import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import Video from '../../components/video'
import RichText from '../../components/rich-text'
import styles from './holding-page.module.scss'
import defaultVideo from './videos/default.mp4'

const HoldingPageTemplate = ({
  heading,
  content,
  businessList,
  footerColumns,
}) => {
  const [isHover, setIsHover] = useState(null)

  const handleOnMouseEnter = event => {
    setIsHover(event.currentTarget.dataset.id)
  }

  const handleOnMouseLeave = () => {
    setIsHover(null)
  }

  return (
    <div className={styles.el}>
      <div className={styles.el__bg}>
        <div className={styles.el__bg__inner}>
          <Video
            src={defaultVideo}
            controls={false}
            loop
            muted
            playing
            fillContainer
          />
        </div>
      </div>
      <div className={styles.el__container}>
        <div className={styles.el__main}>
          <h3 className={styles.el__heading}>
            <RichText>{ReactHtmlParser(heading)}</RichText>
          </h3>
          <div className={styles.el__content}>
            {content && (
              <div className={styles.el__content__text}>
                <RichText>{ReactHtmlParser(content)}</RichText>
              </div>
            )}
            {!!businessList.length && (
              <ul className={styles.el__businessList}>
                {businessList.map(business => {
                  return (
                    <li
                      className={styles.el__businessList__item}
                      key={business.id}
                    >
                      {business.url ? (
                        <a
                          href={business.url}
                          className={styles.el__businessList__link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={handleOnMouseEnter}
                          onMouseLeave={handleOnMouseLeave}
                          data-id={business.id}
                          data-state={
                            isHover === null || isHover === business.id
                              ? 'active'
                              : 'inactive'
                          }
                        >
                          {business.name}
                        </a>
                      ) : (
                        business.name
                      )}
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>
        <footer className={styles.el__footer}>
          <div className={styles.el__footer__inner}>
            <div className={styles.el__footer__container}>
              {footerColumns.map(column => (
                <div className={styles.el__footer__col} key={column.id}>
                  <RichText>{ReactHtmlParser(column.content)}</RichText>
                </div>
              ))}
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

HoldingPageTemplate.defaultProps = {
  heading: 'Coming Soon',
  content: 'We are working on a new version. Stay tuned!',
  businessList: [],
  footerColumns: [],
}

HoldingPageTemplate.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
  businessList: PropTypes.arrayOf(PropTypes.object),
  footerColumns: PropTypes.arrayOf(PropTypes.object),
}

export default HoldingPageTemplate
