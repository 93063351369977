import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import mergePreviewData from '../utils/merge-preview-data'
import HoldingPageTemplate from '../../templates/holding-page'

const FlexiblePrismic = ({ data }) => {
  const { prismicHoldingPage } = mergePreviewData(data)

  const footerColumns = []
  prismicHoldingPage.data.footer_columns.forEach((column, index) => {
    footerColumns.push({
      id: `footer-col-${index}`,
      content: get(column, 'content.html'),
    })
  })

  const businessList = []
  prismicHoldingPage.data.business_list.forEach((business, index) => {
    businessList.push({
      id: `business-list-${index}`,
      name: get(business, 'name'),
      url: get(business, 'url'),
    })
  })

  return (
    <HoldingPageTemplate
      heading={get(prismicHoldingPage, 'data.heading.html')}
      content={get(prismicHoldingPage, 'data.content.html')}
      businessList={businessList}
      footerColumns={footerColumns}
    />
  )
}

FlexiblePrismic.defaultProps = {
  data: {},
}

FlexiblePrismic.propTypes = {
  data: PropTypes.object,
}

export default FlexiblePrismic

export const pageQuery = graphql`
  query HoldingPageByUid($uid: String!) {
    prismicHoldingPage(uid: { eq: $uid }) {
      uid
      type
      data {
        title
        heading {
          html
        }
        content {
          html
        }
        business_list {
          name
          url
        }
        footer_columns {
          content {
            html
          }
        }
      }
    }
  }
`
